<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-card-text v-if="showScreen" class="">
        <jobs-list-view v-on:job-load="load" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import Swal from "sweetalert2";
  import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
  import JobsListView from "./JobsListView";
  import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
  import moment from "moment";
  import Loading from "vue-loading-overlay";
  import { mixins } from "vue-chartjs";
  export default {
    mixins: [checkPermissionsMixin],
    created() {
      if (localStorage.getItem("first-load")) {
        localStorage.removeItem("first-load");
      }
      this.$eventBus.$on("close-job-profile", () => {
        this.dialog = false;
      });
    },
    components: {
      JobsListView,
      JobsProfile,
      Loading,
    },
    data: () => ({
      active: "0",
      dialog: false,
      showScreen: true,
      loading: true,
    }),
    beforeDestroy() {
      this.$eventBus.$off("permission");
    },
    methods: {
      load(event) {
        this.loading = event;
      },
      getDefaultProfile() {
        return Object.assign(
          {},
          {
            jobId: "NA",
            waybillNumber: "",
            from: null,
            to: null,
            amount: null,
            notes: null,
            driverName: null,
            waybillNumber: null,
            status: null,
            customerName: null,
            customerCurrency: null,
            currentStatus: null,
            assignmentsRequired: null,
            timeZoneId: null,
            startDate: null,
            deliveryDate: null,
            otherDetails: null,
            packingType: null,
            cargoWeight: null,
            quantity: null,
            sourceCity: null,
            sourceCountry: null,
            destinationCity: null,
            destinationCountry: null,
            natureOfCargo: null,
            orderType: null,
            truckType: null,
            status: -2,
          }
        );
      },
    },
  };
</script>

<style></style>
